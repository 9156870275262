@use "sass:math";
$is-rtl: false;
$rfs-breakpoint: 1400px;
$rfs-base-value: 10px;
$rfs-rem-value: 12px;
// Colors
$primary: #ffb900;
$secondary: #f77e0b;

$success: #26ab89;
$info: #3866df;
$warning: #ffc600;
$danger: #e00000;
// $light: $gray-100;
$dark: #000;
$body-bg: #f8f8f8;
$body-color: #000000;

$gray-300: #f1f1f1;
$gray-400: #e1e3e2;
$gray-500: #e5e5e5;

$grid-gutter-width: 16px;
$font-family-base: "Somar", sans-serif;
// $headings-font-family: 'PT Serif', serif;
$font-family-ar: "Somar", sans-serif;
$font-weight-base: 300;
$font-weight-bold: 600;
$headings-font-weight: 600;
$btn-font-weight: 600;
$font-weight-bolder: 600;
$font-weight-medium: 500;

$link-color: inherit;
$link-decoration: none;
$link-hover-decoration: none;
$link-hover-color: $secondary;

$border-radius: 8px;
$border-radius-sm: 4px;
$border-radius-lg: 8px;
$border-radius-xl: 12px;
$input-btn-padding-y: 0.6rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-y-lg: 0.938rem;
$input-btn-padding-x-lg: 1.5rem;
$input-btn-font-size-lg: 1rem;
$input-border-color: #fff;
$input-bg: #fff;
// $btn-border-radius: 100rem;
// $btn-border-radius-sm: 100rem;
// $btn-border-radius-lg: 100rem;
// $input-border-radius: 20px;
// $input-border-radius-sm: 20px;
// $input-border-radius-lg: 20px;
// $form-select-border-radius: 20px;

$box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.08);

$modal-content-bg: $body-bg;
$modal-inner-padding: 2rem;

$form-check-input-border-radius: 0;
$form-check-margin-bottom: 0.3rem;
$form-select-bg-position: right 0.5rem center;
$form-check-input-border: 1px solid $dark;

@function aspectRatio($width, $height) {
  @return math.div($height, $width) * 100%;
}

@mixin scrollBar {
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.3em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary, 0.5);
    outline: 0 solid $gray-400;
    border-radius: 20em;

    &:hover {
      background: rgba($primary, 0.8);
    }
  }
}
@function rem($size) {
  @return math.div($size, 12px) * 1rem;
}

// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
