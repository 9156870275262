// Spacing
$sizeUnit: rem;
$marginKey: "m";
$paddingKey: "p";
$separator: "-";
$sizes: (
  ("none", 0),
  ("xxs", 8px),
  ("xs", 14px),
  ("sm", 20px),
  ("md", 24px),
  ("lg", 40px),
  ("xl", 80px),
  ("xxl", 120px)
);
$positions: (("t", "top"), ("s", "left"), ("b", "bottom"), ("e", "right"));

@function sizeValue($key, $value) {
  @return if($key== "none", 0, $value + $sizeUnit);
}
[dir="ltr"] {
  @each $size in $sizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);

    .#{$marginKey}#{$separator}#{$sizeKey} {
      @include margin($sizeValue);
    }

    .#{$paddingKey}#{$separator}#{$sizeKey} {
      @include padding($sizeValue);
    }

    @each $position in $positions {
      $posKey: nth($position, 1);
      $posValue: nth($position, 2);

      .#{$marginKey}#{$posKey}#{$separator}#{$sizeKey} {
        @include rfs($sizeValue, margin-#{$posValue});
      }

      .#{$paddingKey}#{$posKey}#{$separator}#{$sizeKey} {
        @include rfs($sizeValue, padding-#{$posValue});
      }
    }
  }
}
.mb-col {
  margin-bottom: $grid-gutter-width;
}
.mt-col {
  margin-top: $grid-gutter-width;
}

// .rounded-pill {
//   border-radius: 20rem !important;
// }
.order {
  &-lg {
    @include media-breakpoint-down(lg) {
      order: -1;
    }
  }

  &-md {
    @include media-breakpoint-down(md) {
      order: -1;
    }
  }

  &-sm {
    @include media-breakpoint-down(sm) {
      order: -1;
    }
  }
}
.fs {
  &-xxl {
    @include font-size(64px);
  }
  &-xl {
    @include font-size(48px);
  }
  &-lg {
    @include font-size(32px);
  }
  &-md {
    @include font-size(24px);
  }
  &-sm {
    @include font-size(18px);
  }
  &-xs {
    @include font-size(14px);
  }
  &-xxs {
    @include font-size(12px);
  }
}

.max {
  &-xxl {
    max-width: rem(1000px);
  }
  &-xl {
    max-width: rem(800px);
  }
  &-lg {
    max-width: rem(600px);
  }
  &-md {
    max-width: rem(400px);
  }
  &-sm {
    max-width: rem(300px);
  }
  &-xs {
    max-width: rem(260px);
  }
  &-xxs {
    max-width: rem(140px);
  }
  &-120 {
    max-width: rem(120px);
  }
  &-100 {
    max-width: rem(100px);
  }
  &-80 {
    max-width: rem(80px);
  }
}

.bg-light {
  &-primary {
    background: rgba($primary, 0.1);
  }
  &-warning {
    background: rgba($warning, 0.1);
  }
  &-dark {
    background: rgba($dark, 0.05);
  }
}
.fw-medium {
  font-weight: $font-weight-bold;
}
.font-base {
  font-family: $font-family-base;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  > * {
    display: flex;
    &:before {
      content: "";
      width: 0.5em;
      height: 0.5em;

      background: $dark;
      border-radius: 20rem;
      display: flex;
      margin: 0.5em 0.5em 0 0;
      flex: 0 0 0.5em;
    }
    &:not(:first-child) {
      margin-top: rem(8px);
    }
  }
  &.is-center {
    > * {
      justify-content: center;
    }
  }
}

.filterList {
  list-style-type: none;
  padding: 0;
  margin: 0 rem(-10px);
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;

  > li {
    padding: 0 rem(10px) rem(10px);
  }
  .is-active {
    color: $secondary;
    position: relative;
    font-weight: $font-weight-medium;
    &:after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      background: $secondary;
      height: 2px;
      z-index: -1;
    }
  }
}

.bg-gray {
  background: $gray-400;
}

.min-50 {
  min-height: 50vh;
}

.appBox {
  background: $gray-300;
  text-align: center;
  max-width: 800px;
  width: 100%;
  @include padding(80px);
  margin-left: auto;
  margin-right: auto;
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  }
}

.fullPage {
  background-image: url("/assets/images/pattern.png");
  background-repeat: repeat;
  min-height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include padding(96px 0);
}

.products-row {
  @media (max-width: 390px) {
    .col-6 {
      width: 100%;
    }
  }
}

.colorsList {
  list-style-type: none;
  padding: rem(8px);
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  transition: 0.3s ease-in;
  > li {
    padding: rem(2px);
  }
  &-item {
    width: rem(16px);
    height: rem(16px);
    border: 1px solid $white;
    display: block;
    border-radius: 20rem;
    overflow: hidden;
    cursor: pointer;
    &.is-active {
      box-shadow: 0 0 0 2px rgba($primary, 0.6);
    }
  }
}

.iconCard {
  padding-top: rem(50px);
  background: $white;
  position: relative;
  z-index: 2;
  border-radius: $border-radius-xl;
  min-height: calc(100% - 50px);
  margin-top: rem(50px);
  @include media-breakpoint-down(sm) {
    padding-top: rem(30px);
    min-height: calc(100% - 30px);
    margin-top: rem(30px);
  }
  &-header {
    width: rem(100px);
    height: rem(100px);
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);
    background: #fef1e5;
    border-radius: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(20px);
    @include media-breakpoint-down(sm) {
      width: rem(60px);
      height: rem(60px);
      top: -30px;
      padding: rem(12px);
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: 80%;
    }
  }
  &-body {
    padding: rem(16px);
    @include media-breakpoint-down(sm) {
      padding: rem(8px);
    }
    > * {
      margin-bottom: rem(8px);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.qtyInput {
  display: flex;
  align-items: center;
  background: $gray-300;
  border-radius: $input-border-radius;
  justify-content: space-between;

  padding: rem(4px) 0;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  user-select: none;
  &-icon {
    height: 100%;
    padding: 0 rem(8px);
    font-size: 1.5em;
    cursor: pointer;
  }
}
.cursor {
  cursor: pointer;
}

.checkList {
  list-style-type: none;
  padding: 0 0 rem(8px);
  margin: 0 rem(-4px);
  display: flex;
  align-items: center;
  overflow: hidden;
  overflow-x: auto;
  @include scrollBar;
  white-space: nowrap;
  user-select: none;
  > li {
    padding: 0 rem(4px);
  }
  span {
    background: $white;
    border-radius: 20rem;
    padding: rem(8px) rem(16px);
    display: block;
    cursor: pointer;
    font-weight: $font-weight-medium;
  }
  input {
    display: none;
    &:checked {
      ~ span {
        background: $secondary;
        color: $white;
      }
    }
  }
}
