html {
  font-size: 12px;
}
a.text-secondary:hover {
  color: $primary !important;
}
.btn-primary,
.btn-info {
  color: $white;
  &:hover,
  &:focus {
    color: $white;
  }
}
.btn-outline-primary,
.btn-outline-secondary {
  &:hover {
    color: $white;
  }
}
.swiper-slide {
  height: auto;
}

.swiper-button-prev,
.swiper-button-next {
  @include rfs(40px, width);
  @include rfs(40px, height);
  border-radius: 20rem;
  color: $primary;
  background: $white;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 20%);
  // display: none;
  &:after {
    @include font-size(18px);
  }
}

.swiper-container {
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
    }
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  cursor: pointer !important;
  pointer-events: auto !important;
}
.swiper-pagination-bullet {
  background: $primary;
}

body dp-date-picker.dp-material {
  display: block;
  width: 100%;
  .dp-picker-input {
    @extend .form-control;
    width: 100% !important;
  }
  .dp-selected {
    background: $primary;
    border-color: $primary;
    &:hover {
      background: $secondary;
    }
  }
  .dp-current-day {
    border-color: $primary;
  }
  .dp-calendar-wrapper {
    border: 0;
  }
  .dp-popup {
    border: 0;
    box-shadow: $box-shadow;
  }
  .dp-calendar-nav-container {
    border: 0;
  }
  .dp-weekdays {
    font-size: 1rem;
  }
  .dp-current-month {
    border-color: $primary;
  }
  .dp-calendar-weekday {
    color: $dark;
    font-weight: $font-weight-medium;
  }
}

.form-check {
  font-size: rem(14px);
}
.form-select-light,
.form-control-light {
  background-color: $gray-300;
}
.icons-input {
  position: relative;
  &-end {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(48px);
    color: $gray-600;
    margin: 1px;
    border-radius: $input-border-radius;
    padding: rem(14px) 0;
    ~ .form-control,
    ~ .form-select {
      padding-right: rem(48px);
    }
  }
}
